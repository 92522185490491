import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mcruz/Documents/Developer/gatsby/appuro-viaespiga-pt/src/components/layout.js";
import MdxLayout from "../components/mdxLayout.js";
import MdxCoverLayout from "../components/mdxCoverLayout.js";
import Seo from '../components/seo.js';
import ogImage from '../../static/og-image/ogg-image-default.png';
import GallerySection1 from '../components/sections/gallerySection1.js';
import GallerySection2 from '../components/sections/gallerySection2.js';
import GallerySection3 from '../components/sections/gallerySection3.js';
import GallerySection4 from '../components/sections/gallerySection4.js';
import GallerySection5 from '../components/sections/gallerySection5.js';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="Fotos" description="A Via Espiga surgiu em 1992, procurando acompanhar, desde esse momento, os mais elevados padrões da moda internacional, colocando-os, ano após ano, ao dispor do mercado português. Loja de vestuário situada na Cidade de Espinho." image={'https://www.viaespiga.com' + ogImage} siteUrl={'https://www.viaespiga.com' + props.pageContext.frontmatter.path} mdxType="Seo" />
    <MdxCoverLayout mdxType="MdxCoverLayout" />
    <MdxLayout mdxType="MdxLayout">
      <h1>{props.pageContext.frontmatter.title}</h1>
      <GallerySection1 mdxType="GallerySection1" />
    </MdxLayout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      